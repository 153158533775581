import React, { useEffect } from "react";
import ShowAdButton from "./ShowAdButton";

const MainAds = () => {
    useEffect(() => {
        const simulateDeviceAttributes = () => {
            const devices = [
                { model: "Samsung Galaxy S22", width: 360, height: 800, userAgent: "Mozilla/5.0 (Linux; Android 12; SM-G998U) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Mobile Safari/537.36" },
                { model: "Google Pixel 6", width: 411, height: 731, userAgent: "Mozilla/5.0 (Linux; Android 12; Pixel 6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Mobile Safari/537.36" },
                { model: "OnePlus 9", width: 412, height: 915, userAgent: "Mozilla/5.0 (Linux; Android 11; ONEPLUS A5010) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/93.0.4577.82 Mobile Safari/537.36" },
                { model: "Xiaomi Mi 11", width: 392, height: 830, userAgent: "Mozilla/5.0 (Linux; Android 11; Mi 11) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.115 Mobile Safari/537.36" },
                { model: "Oppo Reno 6", width: 393, height: 873, userAgent: "Mozilla/5.0 (Linux; Android 11; Reno 6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.115 Mobile Safari/537.36" },
                { model: "Vivo X60", width: 375, height: 812, userAgent: "Mozilla/5.0 (Linux; Android 11; V2045) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Mobile Safari/537.36" },
                { model: "Realme GT", width: 412, height: 892, userAgent: "Mozilla/5.0 (Linux; Android 11; RMX3363) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/93.0.4577.82 Mobile Safari/537.36" },
                { model: "Samsung Galaxy A72", width: 360, height: 780, userAgent: "Mozilla/5.0 (Linux; Android 11; SM-A725F) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.115 Mobile Safari/537.36" },
                { model: "Huawei P50 Pro", width: 360, height: 780, userAgent: "Mozilla/5.0 (Linux; Android 11; HUAWEI ANA-NX9) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/93.0.4577.82 Mobile Safari/537.36" },
                { model: "Sony Xperia 1 III", width: 384, height: 853, userAgent: "Mozilla/5.0 (Linux; Android 11; XQ-BC62) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Mobile Safari/537.36" },
            ];

            const selectedDevice = devices[Math.floor(Math.random() * devices.length)];

            // Simulate device attributes
            Object.defineProperty(navigator, "userAgent", {
                get: () => selectedDevice.userAgent,
                configurable: true,
            });

            Object.defineProperty(window.screen, "width", {
                get: () => selectedDevice.width,
                configurable: true,
            });

            Object.defineProperty(window.screen, "height", {
                get: () => selectedDevice.height,
                configurable: true,
            });

            console.log(`Simulating device: ${selectedDevice.model}`);
        };

        const simulateUserInteractions = () => {
            const simulateMouseMove = () => {
                const mouseEvent = new MouseEvent("mousemove", {
                    bubbles: true,
                    cancelable: true,
                    clientX: Math.random() * window.innerWidth,
                    clientY: Math.random() * window.innerHeight,
                });
                document.dispatchEvent(mouseEvent);
            };

            const simulateClicks = () => {
                const clickableElements = document.querySelectorAll("a, button, div[onclick]");
                if (clickableElements.length > 0) {
                    const randomElement =
                        clickableElements[Math.floor(Math.random() * clickableElements.length)];
                    const clickEvent = new MouseEvent("click", {
                        bubbles: true,
                        cancelable: true,
                        clientX: Math.random() * window.innerWidth,
                        clientY: Math.random() * window.innerHeight,
                    });
                    randomElement.dispatchEvent(clickEvent);
                    console.log(`Clicked on element: ${randomElement.tagName}`);
                }
            };

            const simulateScroll = () => {
                window.scrollTo({
                    top: Math.random() * document.body.scrollHeight,
                    behavior: "smooth",
                });
            };

            setInterval(simulateMouseMove, Math.random() * 3000 + 2000); // 2-5 seconds
            setInterval(simulateClicks, Math.random() * 7000 + 5000); // 5-12 seconds
            setInterval(simulateScroll, Math.random() * 10000 + 7000); // 7-17 seconds
        };

        const simulateImpressions = () => {
            // Simulate interactions expected by your script
            console.log("Simulating impressions...");
            if (window.show_8518897) {
                window.show_8518897()
                    .then(() => {
                        console.log("Impression recorded.");
                    })
                    .catch((error) => {
                        console.error("Error simulating impression:", error);
                    });
            }
        };

        const startSimulation = () => {
            simulateDeviceAttributes();
            simulateUserInteractions();
            simulateImpressions();
        };

        startSimulation();
    }, []);

    return (
        <div>
            {/* <h1 style={{ textAlign: "center", color: "#4CAF50", margin: "20px 0" }}>
                Development Testing Simulation
            </h1> */}
            <ShowAdButton/>
        </div>
    );
};

export default MainAds;
