import React, { useEffect, useRef, useState } from "react";

// Hook to interact with Telegram Mini App environment
const useTelegram = () => {
    const [theme, setTheme] = useState({
        bg_color: "#ffffff",
        text_color: "#000000",
        button_color: "#0088cc",
    });

    const [user, setUser] = useState(null);

    useEffect(() => {
        if (window.Telegram?.WebApp) {
            const { WebApp } = window.Telegram;

            // Initialize Telegram WebApp
            WebApp.ready();

            // Get user and theme information from Telegram
            setUser(WebApp.initDataUnsafe?.user || null);

            const themeParams = WebApp.themeParams || {};
            setTheme({
                bg_color: themeParams.bg_color || "#ffffff",
                text_color: themeParams.text_color || "#000000",
                button_color: themeParams.button_color || "#0088cc",
            });

            console.log("Telegram WebApp initialized:", WebApp);
        } else {
            console.error("Telegram WebApp environment not found.");
        }
    }, []);

    return { theme, user };
};

const ShowAdButton = () => {
    const [clickCount, setClickCount] = useState(
        () => parseInt(localStorage.getItem("clickCount"), 10) || 0
    ); // Load clickCount from localStorage on initial load
   
    const buttonRef = useRef(null);
    const backgroundInterval = useRef(null);
    const preventSleepInterval = useRef(null);
    const silentAudio = useRef(null);

    // Use Telegram environment
    const { theme, user } = useTelegram();

    useEffect(() => {
        // Inject the ad script dynamically
        const adScriptUrl = "//niphaumeenses.net/vignette.min.js";
        const adScriptId = "8518897";

        if (!window[`show_${adScriptId}`]) {
            const tag = document.createElement("script");
            tag.src = adScriptUrl;
            tag.dataset.zone = adScriptId;
            tag.dataset.sdk = `show_${adScriptId}`;
            document.body.appendChild(tag);

            return () => {
                document.body.removeChild(tag);
            };
        }

        // Auto-click every 13 seconds
        const startBackgroundInterval = () => {
            backgroundInterval.current = setInterval(() => {
                if (buttonRef.current) {
                    buttonRef.current.click();
                }
            }, 13000);
        };

        startBackgroundInterval();

        // Ensure background interval is cleared on unmount
        return () => clearInterval(backgroundInterval.current);
    }, []);

    useEffect(() => {
        // Prevent screen sleep by simulating user activity
        const preventScreenSleep = () => {
            const simulateMouseMove = () => {
                const mouseEvent = new MouseEvent("mousemove", {
                    bubbles: true,
                    cancelable: true,
                    clientX: Math.random() * window.innerWidth,
                    clientY: Math.random() * window.innerHeight,
                });
                document.dispatchEvent(mouseEvent);
            };

            const simulateTouch = () => {
                const touch = new Touch({
                    identifier: Date.now(),
                    target: document.body,
                    clientX: Math.random() * window.innerWidth,
                    clientY: Math.random() * window.innerHeight,
                    radiusX: 1,
                    radiusY: 1,
                    rotationAngle: 0,
                    force: 1.0,
                });

                const touchEvent = new TouchEvent("touchstart", {
                    bubbles: true,
                    cancelable: true,
                    touches: [touch],
                    targetTouches: [touch],
                    changedTouches: [touch],
                });

                document.body.dispatchEvent(touchEvent);
            };

            preventSleepInterval.current = setInterval(() => {
                simulateMouseMove();
                simulateTouch();
            }, 5000);

            return () => clearInterval(preventSleepInterval.current);
        };

        // Play silent audio after user interaction
        const playSilentAudio = () => {
            const audio = new Audio(
                "data:audio/wav;base64,UklGRiQAAABXQVZFZm10IBAAAAABAAEAgLsAAAB3AQACABAAZGF0YQAAAAA="
            );
            audio.loop = true;
            silentAudio.current = audio;

            const enableAudioOnClick = () => {
                silentAudio.current
                    .play()
                    .then(() => {
                        console.log("Silent audio playback started");
                    })
                    .catch((err) => console.error("Silent audio playback failed:", err));

                document.removeEventListener("click", enableAudioOnClick);
            };

            // Wait for a user interaction to enable audio
            document.addEventListener("click", enableAudioOnClick);
        };

        preventScreenSleep();
        playSilentAudio();

        return () => {
            if (silentAudio.current) {
                silentAudio.current.pause();
                silentAudio.current = null;
            }
            clearInterval(preventSleepInterval.current);
        };
    }, []);

    const showAd = () => {
        const adFunction = window.show_8518897;
        if (adFunction) {
            adFunction()
                .then(() => {
                    console.log("Ad has been viewed");
                    setClickCount((prev) => {
                        const newCount = prev + 1;
                        localStorage.setItem("clickCount", newCount); // Save to localStorage
                        return newCount;
                    });
                })
                .catch((error) => {
                    console.error("Error showing the ad:", error);
                });
        } else {
            console.error("Ad script not yet loaded.");
        }
    };

    const sendDataToTelegram = () => {
        if (window.Telegram?.WebApp) {
            window.Telegram.WebApp.sendData(
                JSON.stringify({ action: "ad_view", clicks: clickCount })
            );
        } else {
            console.error("Telegram WebApp environment not available.");
        }
    };

    return (
        <div
        style={{
            height: "100vh",
            background: "linear-gradient(135deg, #00c6ff, #0072ff)",
            color: theme.text_color,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px",
            textAlign: "center",
            fontFamily: "Arial, sans-serif",
        }}
    >
        <h1
            style={{
                color: "#ffffff",
                fontSize: "2.5rem",
                fontWeight: "bold",
                marginBottom: "10px",
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
            }}
        >
            🎉 Watch Ads, Earn Money! 🎉
        </h1>
        <p
            style={{
                color: "#f9f9f9",
                fontSize: "1.2rem",
                marginBottom: "30px",
                lineHeight: "1.5",
            }}
        >
            Watch ads, increase your balance, and cash out instantly. It’s simple, fun, and rewarding!
        </p>
        <button
            ref={buttonRef}
            onClick={showAd}
            style={{
                backgroundColor: "#ff9800",
                color: "#ffffff",
                fontSize: "1.2rem",
                padding: "15px 30px",
                border: "none",
                borderRadius: "30px",
                cursor: "pointer",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                marginBottom: "20px",
                transition: "transform 0.2s, box-shadow 0.2s",
            }}
            onMouseOver={(e) =>
                (e.target.style.transform = "scale(1.05)")
            }
            onMouseOut={(e) =>
                (e.target.style.transform = "scale(1.0)")
            }
        >
            🎥 Watch Ad Now
        </button>
        <p
            style={{
                color: "#ffffff",
                fontSize: "1.2rem",
                marginBottom: "20px",
            }}
        >
            Total Ads Watched: <strong>{clickCount}</strong>
        </p>
        <button
            onClick={sendDataToTelegram}
            style={{
                backgroundColor: "#4caf50",
                color: "#ffffff",
                fontSize: "1.2rem",
                padding: "15px 30px",
                border: "none",
                borderRadius: "30px",
                cursor: "pointer",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                transition: "transform 0.2s, box-shadow 0.2s",
            }}
            onMouseOver={(e) =>
                (e.target.style.transform = "scale(1.05)")
            }
            onMouseOut={(e) =>
                (e.target.style.transform = "scale(1.0)")
            }
        >
            💰 Cash Out Now
        </button>
    </div>
    );
};

export default ShowAdButton;
